<div class="p-2 max-width">
  <mat-card>
    <mat-card-content>
      <h2>Theme Name</h2>
      <div>
        <mat-radio-group aria-label="Select a theme" [(ngModel)]="themeName" color="primary">
          <ng-container *ngFor="let option of themeOptions()">
            <mat-radio-button class="d-block" [value]="option.value">{{option.view}}</mat-radio-button>
          </ng-container>
        </mat-radio-group>
      </div>
    </mat-card-content>
  </mat-card>
</div>
