import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import { HTTPCONSTANTS, MerchantService } from '@odin/odin-core';
import {ReceiptConfiguration} from "../models/receipt-configuration";
import {HttpClient} from "@angular/common/http";


@Injectable({ providedIn: 'root' })
export class ReceiptSettingsService {

  constructor(private merchantService: MerchantService, private http: HttpClient) {}

  getConfig(): Observable<ReceiptConfiguration> {
    const merchantRef = this.merchantService.getCurrentMerchant();
    if (merchantRef === null) return of();
    return this.http.get<ReceiptConfiguration>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.odinBaseAddress}/transactions/receipt/${merchantRef.id}`,
    );
  }

  createConfig(config: ReceiptConfiguration): Observable<ReceiptConfiguration> {
    const merchantRef = this.merchantService.getCurrentMerchant();
    if (merchantRef === null) return of();
    return this.http.post<ReceiptConfiguration>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.odinBaseAddress}/transactions/receipt/${merchantRef.id}`,
      config,
    );
  }

  updateConfig(config: ReceiptConfiguration): Observable<ReceiptConfiguration> {
    const merchantRef = this.merchantService.getCurrentMerchant();
    if (merchantRef === null) return of();
    return this.http.put<ReceiptConfiguration>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.odinBaseAddress}/transactions/receipt/${merchantRef.id}`,
      config,
    );
  }

  deleteConfig(): Observable<object> {
    const merchantRef = this.merchantService.getCurrentMerchant();
    if (merchantRef === null) return of();
    return this.http.delete(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.odinBaseAddress}/transactions/receipt/${merchantRef.id}`,
    );
  }

  resendPaymentConfirmation(crossRef: string): Observable<ReceiptConfiguration> {
    const merchantRef = this.merchantService.getCurrentMerchant();
    if (merchantRef === null) return of();
    return this.http.get<ReceiptConfiguration>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.odinBaseAddress}/transactions/receipt/${merchantRef.id}`,
    );
  }

}
