import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OdinSettingsPageComponent } from './odin-settings-page/odin-settings-page.component';
import {ReceiptConfigurationPageComponent} from "./receipt-configuration-page/receipt-configuration-page.component";

export const routes: Routes = [
  { path: 'theme', component: OdinSettingsPageComponent },
  { path: 'receipts', component: ReceiptConfigurationPageComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OdinSettingsRoutingModule {}
