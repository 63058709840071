import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionsLayoutModule } from './transactions-layout/transactions-layout.module';
import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CorePipesModule, OdinCoreModule } from '@odin/odin-core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { PeblTrackingDetailsComponent } from './pebl-tracking-details/pebl-tracking-details.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RefundTransactionModalComponent } from './transactions-layout/components/refund-transaction-modal/refund-transaction-modal.component';
import { RechargeTransactionModalComponent } from './transactions-layout/components/recharge-transaction-modal/recharge-transaction-modal.component';
import { ReverseTransactionModalComponent } from './transactions-layout/components/reverse-transaction-modal/reverse-transaction-modal.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    MatChipsModule,
    MatTooltipModule,
    MatDividerModule,
    ScrollingModule,
    OdinCoreModule,
    CorePipesModule,
    MatDialogModule,
    TransactionsLayoutModule,
  ],
  exports: [
    ReverseTransactionModalComponent,
    RefundTransactionModalComponent,
    RechargeTransactionModalComponent,
    PeblTrackingDetailsComponent
  ],
  providers: [],
  declarations: [
    ReverseTransactionModalComponent,
    RefundTransactionModalComponent,
    RechargeTransactionModalComponent,
    PeblTrackingDetailsComponent,
  ],
})
export class OdinTransactionsModule {}
