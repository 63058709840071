import { Injectable } from '@angular/core';
import { NavigationItem } from '../Models/NavigationItem';
import {
  faCashRegister,
  faCog,
  faCoins,
  faExclamation,
  faHeadset,
  faHome,
  faLink,
  faMagnifyingGlassChart,
  faPaintRoller,
  faPepperHot,
  faReceipt,
  faSignOutAlt,
  faSterlingSign,
  faUserCircle,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons';
import { NavigationEnd, Router } from '@angular/router';
import { SettingsService } from '@odin/odin-settings';
import {
  AccessGuardService,
  CognitoService,
  UserAccessLevel,
} from '@odin/odin-authentication';
import { environment } from '../../environments/environment';
import { MerchantChangeService } from '@odin/odin-core';

@Injectable({ providedIn: 'root' })
export class OdinNavigationService {
  public navigationRoutes: NavigationItem[] = this.buildNav();

  public buildNav(): NavigationItem[] {
    const navigation = [
      new NavigationItem(
        '/dashboard',
        'dashboard',
        'Dashboard',
        faHome,
        false,
        true,
      ),
      new NavigationItem(
        '/transactions',
        'transactions',
        'Transactions',
        faReceipt,
        true,
        true,
      ),
      new NavigationItem(
        '/transactions/settlements',
        'transactions/settlements',
        'Statements',
        faSterlingSign,
        true,
        true,
      ),
      new NavigationItem('', '', 'Take a payment', faCoins, true, true, undefined, {
        subRoutes: [
          new NavigationItem(
            '/virtual-terminal/VT',
            'virtual-terminal/VT',
            'Virtual Terminal',
            faHeadset,
            false,
            environment.featureToggles['vt-page'] &&
            this.accessService.hasGrant('txn:Create'),
          ),
          new NavigationItem(
            '/virtual-terminal/PEBL',
            'virtual-terminal/PEBL',
            'Payment Link',
            faLink,
            false,
            environment.featureToggles['pebl-page'] &&
            this.accessService.hasGrant('txn:CreatePebl'),
          ),
          new NavigationItem(
            '/virtual-terminal/PEBL-tracking',
            'virtual-terminal/PEBL-tracking',
            'Link Tracking',
            faMagnifyingGlassChart,
            false,
            environment.featureToggles['pebl-page'] &&
            this.accessService.hasGrant('txn:CreatePebl'),
          ),
        ],
        opened:
          this.navigationRoutes !== undefined &&
          this.navigationRoutes.filter(
            (x) => x.viewName === 'Take a payment',
          )[0].advanced?.opened,
      }),
      new NavigationItem(
        '',
        '',
        "Settings",
        faCog,
        true,
        true,
        undefined,
        {
          subRoutes: [
            new NavigationItem(
              '/settings/theme',
              'settings',
              'Theming',
              faPaintRoller,
              true,
              true,
            ),
            new NavigationItem(
              '/settings/receipts',
              'receipts',
              'Receipts',
              faReceipt,
              true,
              environment.featureToggles['receipts'] &&
              this.accessService.hasGrant('txn:Receipts')
            ),
            new NavigationItem(
              '/integrations',
              'integrations',
              'Integrations',
              faLink,
              true,
              environment.featureToggles['integrations'] &&
              this.accessService.hasGrant('odn:Integrations')
            )
          ]
        }
      ),
      new NavigationItem(
        '/admin',
        'admin',
        'Users',
        faUserShield,
        true,
        this.accessService.isMinLevel(UserAccessLevel.MerchantAdmin),
        '/admin',
        {
          pathMatch: 'full'
        }
      ),
      new NavigationItem(
        '/monek-admin',
        'monek-admin',
        'Monek Admin',
        faPepperHot,
        true,
        this.accessService.hasAccessLevel(UserAccessLevel.Admin),
      ),
      new NavigationItem(
        `/admin/user/${this.cognitoService.userProfile()?.userId}`,
        'profile',
        'Profile',
        faUserCircle,
        true,
        this.accessService.hasAccessLevel(UserAccessLevel.Admin) && this.cognitoService.userProfile() != null,
        `/admin/user`
      ),
      new NavigationItem('',
        'logout',
        'Logout',
        faSignOutAlt,
        false,
        true,
        undefined,
        {
          action: () => this.cognitoService.logout(),
        }),
    ];

    return navigation;
  }

  private getRouteByPath(
    route: string,
    item: NavigationItem,
  ): NavigationItem | undefined {
    item.active = false;
    if (item.hasSubRoutes()) {
      const subRouteRes = item.advanced?.subRoutes?.filter(
        (nr: NavigationItem) => {
          return this.getRouteByPath(route, nr);
        },
      );
      if (subRouteRes === undefined) return undefined;
      return subRouteRes.length > 0 ? subRouteRes[0] : undefined;
    }

    // actual matching
    if (item.matchingRoute === '') return undefined;
    if (item.advanced && item.advanced.pathMatch === 'full') {
      if (item.matchingRoute === route) return item;
    } else {
      if (item.matchingRoute.indexOf(route) > -1) {
        return item;
      }
    }
    return undefined;
  }

  public get currentRoute(): NavigationItem {
    let _url = this.router.url;
    if (_url === '/')
      return new NavigationItem('', '', '', faExclamation, false, false);

    let currRoute = null;
    const attempts = this.navigationRoutes.length;
    let attempt = 0;

    while (currRoute == null && attempt <= attempts && _url !== '') {
      let _ = this.navigationRoutes.map((nr: NavigationItem) => {
        return this.getRouteByPath(_url, nr);
      });
      _ = _.filter((__) => __ !== undefined);

      currRoute = _[0];

      const routeEnd = _url.lastIndexOf('/');
      if (routeEnd > -1) _url = _url.substr(0, routeEnd);
      attempt++;
    }

    if (currRoute == null)
      return new NavigationItem('', '', '', faExclamation, false, false);

    currRoute.active = true;
    return currRoute;
  }

  constructor(
    private router: Router,
    private settingsService: SettingsService,
    private cognitoService: CognitoService,
    private accessService: AccessGuardService,
    private mercahtChangeService: MerchantChangeService,
  ) {
    const restrictedRoutes = ['terminals'];
    this.navigationRoutes = this.navigationRoutes.filter(
      (x) => !restrictedRoutes.includes(x.safeRoute),
    );
    this.cognitoService.loginChanged.subscribe(() => {
      this.navigationRoutes = this.buildNav();
    });

    // handles BUID change, adds/removes from navigation for grants
    this.mercahtChangeService.merchantChangeEvent.subscribe(() => {
      this.navigationRoutes = this.buildNav();
    });

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.indexOf('authentication') == -1 &&
          this.currentRoute.route.indexOf('authentication') == -1 &&
          this.currentRoute.route != ''
        ) {
          this.navigationRoutes = this.navigationRoutes.map((x) => {
            if (!x.hasSubRoutes()) return x;
            if (x.hasActiveSubRoute()) return x;
            if (x.advanced !== undefined) x.advanced.opened = false;
            return x;
          });
          this.settingsService.SetSetting('lastRoute', this.currentRoute.route);
        }
      }
    });
  }
}
