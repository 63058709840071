import { Component, Inject } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {IFormattedTransaction, TransactionRefund} from '../../models';
import {
  Alignment,
  ConfirmationService,
  FormBuilder,
  FormData,
  FormValidation,
  FormField,
  InputType,
  MonekColour,
  NotificationService,
  Size,
  VTService,
  ValidationPattern,
  MerchantService,
} from '@odin/odin-core';
import { VTPaymentResponse } from '../../models/vt-payment';
import { CurrencyPipe } from '@angular/common';

export class RefundModalData {
  constructor(public transaction: IFormattedTransaction) {}
}

/** Error when invalid control is dirty, touched, or submitted. */
export class AmountErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  standalone: false,
  selector: 'odin-refund-transaction-modal',
  templateUrl: './refund-transaction-modal.component.html',
  styleUrls: ['./refund-transaction-modal.component.scss'],
})
export class RefundTransactionModalComponent {
  private formattedTransaction: IFormattedTransaction;
  public refundForm: FormData<TransactionRefund>;
  public amountMatcher = new AmountErrorStateMatcher();
  public processing: boolean = false;
  public remainingAmount: number;

  constructor(
    private merchantService: MerchantService,
    public dialogRef: MatDialogRef<RefundTransactionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RefundModalData,
    private VTService: VTService,
    private notifcationService: NotificationService,
    private confirmationService: ConfirmationService,
    private currencyPipe: CurrencyPipe,
  ) {
    this.formattedTransaction = this.data.transaction;

    this.remainingAmount =
      this.formattedTransaction.remainingValue !== null &&
      this.formattedTransaction.remainingValue !== undefined
        ? Number.parseFloat(this.formattedTransaction.remainingValue.toFixed(2))
        : this.formattedTransaction.amount;

    this.refundForm = this.BuildForm();
  }

  private BuildForm(): FormData<TransactionRefund> {
    const userProfile = this.merchantService.getUserprofile();
    const formData = new FormBuilder<TransactionRefund>(MonekColour.Primary)
      .ToggleSubmitButton(true)
      .AddFormField(
        'OperatorId',
        '',
        InputType.Hidden,
        userProfile?.userId || '',
        Size.Full,
      )
      .AddFormField(
        'OperatorName',
        '',
        InputType.Hidden,
        userProfile?.email || '',
        Size.Full,
      )
      .SubmitButtonConfig('Complete', Size.Full, Alignment.Center)
      .AddFormField(
        'PaymentChannel',
        '',
        InputType.Hidden,
        this.formattedTransaction.channel,
        Size.Auto,
      )
      .AddFormField('AuxiliaryData', '', InputType.Hidden, '', Size.Auto)
      .AddFormField('MerchantID', '', InputType.Hidden, '', Size.Auto)
      .AddFormField('CountryCode', '', InputType.Hidden, this.formattedTransaction.merchantCountry, Size.Auto)
      .AddFormField('CurrencyCode', '', InputType.Hidden, this.formattedTransaction.currencyCode, Size.Auto)
      .AddFormField('ValidityID', '', InputType.Hidden, null, Size.Auto)
      .AddFormField(
        'CrossReference',
        '',
        InputType.Hidden,
        this.formattedTransaction.crossReference,
        Size.Auto,
      )
      .AddFormField(
        'Amount',
        `Amount (${this.currencyPipe.transform(
          this.remainingAmount,
          this.formattedTransaction.currencyCountry,
        )})`,
        InputType.Number,
        null,
        Size.Full,
        new FormValidation()
          .Required('Amount is required')
          .MinValue(
            0.01,
            `Amount must be greater than ${this.currencyPipe.transform(
              0.01,
              this.formattedTransaction.currencyCountry,
            )}`,
          )
          .MaxValue(
            this.remainingAmount,
            `Amount can not exceed ${this.currencyPipe.transform(
              this.remainingAmount,
              this.formattedTransaction.currencyCountry,
            )}`,
          )
          .MaxLength(9)
          .PatternMatch(ValidationPattern.Decimal, 'Amount must be a number'),
        true,
        {},
        undefined,
        (formfield: FormField, value: string) => {
          if (value !== '') {
            const numericValue = parseFloat(value);
            if (isNaN(numericValue)) {
              formfield.SetValue('0.00');
            } else {
              formfield.SetValue(numericValue.toFixed(2));
            }
          }
        },
      )
      .Build((data: TransactionRefund) => {
        this.makeRefund(data);
      });
    return formData;
  }

  private makeRefund(data: TransactionRefund): void {
    this.processing = true;

    this.confirmationService
      .ConfirmDecline(
        'Are you sure to process this refund?',
        'By selecting “Yes” the refund will be deducted from the original transaction, please select to confirm.',
        'Yes',
        'Cancel',
        'primary',
        'warn',
      )
      .subscribe((resp: boolean) => {
        if (resp) this.executeRefund(data);
        else this.processing = false;
      });
  }
  private executeRefund(data: TransactionRefund): void {
    this.VTService.SubmitRefundRequest(data).subscribe(
      (_resp: VTPaymentResponse) => {
        if (_resp.status) {
          this.dialogRef.close(null);
          this.notifcationService.SmallDialog(
            'Refund completed',
            'Close',
            60000,
          );
        } else {
          this.refundForm.ShowError(`${_resp.traceId} - ${_resp.message}`);
        }
        this.processing = false;
      },
      () => {
        this.refundForm.ShowError('Something went wrong.');
        this.processing = false;
      },
    );
  }

  // close without saving
  public cancel(): void {
    this.dialogRef.close(null);
  }
}
