import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PullToRefreshComponent } from './components/pull-to-refresh/pull-to-refresh.component';
import { DynamicInputComponent } from './components/dynamic-input/dynamic-input.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { MatchMediaService } from './services/match-media.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DashboardPanelComponent } from './components/dashboard-panel/dashboard-panel.component';
import { MatIconModule } from '@angular/material/icon';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { CashCalendarComponent } from './components/cash-calendar/cash-calendar.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ChartComponent } from './components/chart/chart.component';
import { SearchBarComponent } from '../lib/components/search-bar/search-bar.component';
import { AnnouncementDisplayComponent } from './components/announcement-display/announcement-display.component';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { CorePipesModule } from './pipes/core.pipes.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ConfirmationService } from './services/confirmation-service';
import { IconDataRowComponent } from './icon-data-row/icon-data-row.component';
import { TransactionFormatterService } from 'libs/odin-transactions/src/lib/transactions-layout/services/transaction-formatter.service';
import { CardHelperService } from './services/card-helper.service';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { DialogAlertComponent } from './dialog-alert/dialog-alert.component';
import { PciWidgetComponent } from './pci-widget/pci-widget.component';
import { PciFormComponent } from './pci-form/pci-form.component';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MerchantPCIService } from './services/merchant-pci.service';
import { MerchantService } from './services/merchant.service';
import { MatSelectModule } from '@angular/material/select';
import CountryConvertService from 'libs/odin-transactions/src/lib/data/country-convert';

@NgModule({
  declarations: [
    LoadingSpinnerComponent,
    CashCalendarComponent,
    SearchBarComponent,
    ChartComponent,
    PullToRefreshComponent,
    DynamicInputComponent,
    DynamicFormComponent,
    DashboardPanelComponent,
    AnnouncementDisplayComponent,
    ConfirmationModalComponent,
    IconDataRowComponent,
    InputDialogComponent,
    DialogAlertComponent,
    PciWidgetComponent,
    PciFormComponent,
  ],
  exports: [
    LoadingSpinnerComponent,
    CashCalendarComponent,
    ChartComponent,
    SearchBarComponent,
    PullToRefreshComponent,
    DynamicInputComponent,
    DynamicFormComponent,
    DashboardPanelComponent,
    AnnouncementDisplayComponent,
    IconDataRowComponent,
    InputDialogComponent,
    DialogAlertComponent,
    PciWidgetComponent,
    PciFormComponent,
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
    // NgxChartsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatIconModule,
    MatNativeDateModule,
    MatListModule,
    MatSelectModule,
    MatCardModule,
    CorePipesModule,
    FontAwesomeModule,
  ],
  providers: [
    DatePipe,
    PullToRefreshComponent,
    MatchMediaService,
    ConfirmationService,
    TransactionFormatterService,
    CountryConvertService,
    CardHelperService,
    MerchantPCIService,
    MerchantService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class OdinCoreModule {}
