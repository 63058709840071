import {Component, OnInit} from '@angular/core';
import {ReceiptSettingsService} from "../services/receipt-settings.service";
import {ReceiptConfiguration} from "../models/receipt-configuration";
import {ConfirmationService, MerchantService} from "@odin/odin-core";

@Component({
  standalone: false,
  selector: 'odin-receipt-configuration-page',
  templateUrl: './receipt-configuration-page.component.html',
  styleUrl: './receipt-configuration-page.component.scss'
})
export class ReceiptConfigurationPageComponent implements OnInit {

  public configLoading: boolean = true;
  public changeLoading: boolean = false;
  public receiptConfiguration: ReceiptConfiguration | null = null;
  public originalReceiptConfiguration: string;
  public emails: string[] = [];

  constructor(private receiptService: ReceiptSettingsService,
              private confirmationService: ConfirmationService,
              private merchantService: MerchantService) {}

  ngOnInit() {
    this.fetchConfig();
  }

  private fetchConfig(): void {
    this.configLoading = true;
    this.receiptService.getConfig().subscribe((config: ReceiptConfiguration) => {
      this.setupConfig(config);
      this.configLoading = false;
    }, (error) => {
      this.configLoading = false;
      this.receiptConfiguration = null;
    })
  }

  private setupConfig(config: ReceiptConfiguration, forcedOriginalConfig?: string): void {
    this.receiptConfiguration = config;
    this.originalReceiptConfiguration = JSON.stringify(config);
    if (forcedOriginalConfig !== undefined) this.originalReceiptConfiguration = forcedOriginalConfig;
    this.emails = [...config.emailConfiguration.merchantEmailAddresses];
    this.changeLoading = false;
    this.configLoading = false;
  }

  public hasChanged(): boolean {
    return JSON.stringify(this.receiptConfiguration) !== this.originalReceiptConfiguration;
  }

  public AddEmail(): void {
    this.emails.push('');
  }

  public RemoveEmail(index: number): void {
    this.emails.splice(index, 1);
  }

  public UpdateEmail(index: number, email: string): void {
    const emails = this.receiptConfiguration?.emailConfiguration?.merchantEmailAddresses;
    if (emails === undefined) return;
    emails[index] = email;
  }

  public createNewConfiguration(): void {
    const merchantRef = this.merchantService.getCurrentMerchant();
    if (merchantRef === null) return;
    const newConfig = {
      businessUnitId: merchantRef.id,
      enabledFor: 2,
      customerDestination: 2,
      merchantDestination: 2,
      tradingName: merchantRef.name,
      emailConfiguration: {
        merchantEmailTemplateId: 1,
        merchantEmailAddresses: [""],
        merchantEmailSubject: "",

        customerEmailSubject: "",
        customerEmailTemplateId: 1,
      },
      smsConfiguration: {
        customerSmsTemplateId: 1,
        merchantSmsTemplateId: 1,
        merchantPhoneNumber: "",
      }
    } as ReceiptConfiguration;
    this.setupConfig(newConfig, 'original');
  }

  public saveNewConfiguration(): void {
    if (this.receiptConfiguration === null) return;
    this.changeLoading = true;
    this.receiptService.createConfig(this.receiptConfiguration).subscribe((config: ReceiptConfiguration) => {
      this.fetchConfig();
    });
  }

  public updateConfiguration(): void {
    if (this.receiptConfiguration === null) return;
    if (this.originalReceiptConfiguration === 'original') {
      this.saveNewConfiguration();
      return;
    } else {
      this.changeLoading = true;
      this.receiptService.updateConfig(this.receiptConfiguration).subscribe((config: ReceiptConfiguration) => {
        this.fetchConfig();
      });
    }
  }

  public deleteConfiguration(): void {
    if (this.receiptConfiguration === null) return;
    this.confirmationService.ConfirmDecline("Delete config", "Are you sure you want to delete this configuration?", "Yes", "Cancel", "warn", "primary").subscribe((res: boolean) => {
      if (!res) return;
      this.changeLoading = true;
      this.receiptService.deleteConfig().subscribe(() => {
        this.receiptConfiguration = null;
        this.originalReceiptConfiguration = '';
        this.changeLoading = false;
        this.fetchConfig();
      });
    });
  }
}
