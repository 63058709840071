<div class="p-2 max-width page-height overflow-auto">
  <mat-card>
    <mat-card-content>
      <div>
      <div class="d-flex align-items-center justify-content-between">
        <h2>Receipt Configuration</h2>

        <button (click)="updateConfiguration()" *ngIf="!configLoading && receiptConfiguration !== null" [disabled]="!hasChanged()" mat-flat-button class="d-block bg-white-always mon-border-success border mon-text-success flex-shrink-0">
          Save
        </button>
      </div>

        <p class="mb-4">Customise Merchant and Customer receipt options, including email subject, recipient phone number and emails to receive receipts</p>
      </div>


      <div class="d-flex align-items-center justify-content-center" *ngIf="configLoading">
        <mat-progress-spinner
          [diameter]="60"
          [strokeWidth]="5"
          [color]="'primary'"
          class="spinner"
          mode="indeterminate"
        ></mat-progress-spinner>
      </div>

      <div *ngIf="!configLoading">

        <div class="position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center" style="z-index: 1000; background: rgba(0,0,0,0.7);" *ngIf="changeLoading">
          <mat-progress-spinner
            [diameter]="60"
            [strokeWidth]="5"
            [color]="'primary'"
            class="spinner"
            mode="indeterminate"
          ></mat-progress-spinner>
        </div>

        <!-- NEW CONFIG -->
        <div *ngIf="!receiptConfiguration">
          <p>A config has not been created</p>
          <button (click)="createNewConfiguration()" mat-flat-button class="d-block bg-white-always mon-border-primary border mon-text-primary flex-shrink-0">
            Create new config
          </button>
        </div>

        <!-- MODIFY CONFIG -->
        <div *ngIf="receiptConfiguration !== null && receiptConfiguration !== undefined">
          <h3 class="my-0">Outbound Settings</h3>
          <div class="bg-light border border-1 rounded p-3 pb-0">
            <mat-form-field class="w-100">
              <mat-label>Send To</mat-label>
              <mat-select [(ngModel)]="receiptConfiguration.enabledFor">
                <mat-option [value]="0">Customers</mat-option>
                <mat-option [value]="1">Merchants</mat-option>
                <mat-option [value]="2">Both</mat-option>
                <mat-option [value]="3">Disabled</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100" *ngIf="receiptConfiguration.enabledFor === 0 || receiptConfiguration.enabledFor === 2">
              <mat-label>Customer Send Type</mat-label>
              <mat-select [(ngModel)]="receiptConfiguration.customerDestination">
                <mat-option [value]="0">E-Mail</mat-option>
                <mat-option [value]="1">SMS</mat-option>
                <mat-option [value]="2">Both</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100" *ngIf="receiptConfiguration.enabledFor === 1 || receiptConfiguration.enabledFor === 2">
              <mat-label>Merchant Send Type</mat-label>
              <mat-select [(ngModel)]="receiptConfiguration.merchantDestination">
                <mat-option [value]="0">E-Mail</mat-option>
                <mat-option [value]="1">SMS</mat-option>
                <mat-option [value]="2">Both</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100" *ngIf="receiptConfiguration.enabledFor !== 3">
              <mat-label>Trading Name</mat-label>
              <input matInput [(ngModel)]="receiptConfiguration.tradingName"/>
            </mat-form-field>
          </div>

          <!-- EMAIL -->
          <div class="mt-4" *ngIf="receiptConfiguration && receiptConfiguration.emailConfiguration">
            <div *ngIf="(receiptConfiguration.enabledFor === 1 || receiptConfiguration.enabledFor === 2) && (receiptConfiguration.merchantDestination === 0 || receiptConfiguration.merchantDestination === 2)">
              <h3 class="mb-1 mt-3">Merchant Email Settings</h3>
              <div class="bg-light border border-1 rounded p-3 pb-0">
                <div>
                  <ng-container *ngFor="let email of emails; let index = index">

                    <div class="d-flex align-items-start justify-content-start">
                    <mat-form-field class="w-100">
                      <mat-label>Merchant Email Address ({{ index + 1 }})</mat-label>
                      <input matInput [ngModel]="receiptConfiguration.emailConfiguration.merchantEmailAddresses[index]"
                      (ngModelChange)="UpdateEmail(index, $event)" />
                    </mat-form-field>

                      <div>
                        <button (click)="RemoveEmail(index)" style="height: 56px; width: 56px;" class="rounded-2 ms-2 d-block mon-bg-danger mon-border-danger border mon-text-danger flex-shrink-0"><mat-icon>delete</mat-icon></button>
                      </div>
                    </div>
                  </ng-container>

                  <div>
                    <button (click)="AddEmail()" mat-flat-button class="d-block mon-bg-success mon-border-success border mon-text-success flex-shrink-0">
                      Add Email
                    </button>
                  </div>
                </div>

                <mat-form-field class="w-100 mt-4">
                  <mat-label>Email Subject</mat-label>
                  <input matInput [(ngModel)]="receiptConfiguration.emailConfiguration.merchantEmailSubject"/>
                </mat-form-field>

                <mat-form-field *ngIf="false" class="w-100">
                  <mat-label>Email Template</mat-label>
                  <input matInput [(ngModel)]="receiptConfiguration!.emailConfiguration!.merchantEmailTemplateId"/>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="(receiptConfiguration.enabledFor === 0 || receiptConfiguration.enabledFor === 2) && (receiptConfiguration.customerDestination === 0 || receiptConfiguration.customerDestination === 2)">
              <h3 class="mb-1 mt-3">Customer Email Settings</h3>
              <div class="bg-light border border-1 rounded p-3 pb-0">
                <mat-form-field class="w-100">
                  <mat-label>Email Subject</mat-label>
                  <input matInput [(ngModel)]="receiptConfiguration.emailConfiguration.customerEmailSubject"/>
                </mat-form-field>

                <mat-form-field *ngIf="false" class="w-100">
                  <mat-label>Email Template</mat-label>
                  <input matInput [(ngModel)]="receiptConfiguration!.emailConfiguration!.customerEmailTemplateId"/>
                </mat-form-field>
              </div>
            </div>
          </div>


          <!-- SMS-->
          <div class="mt-4" *ngIf="receiptConfiguration && receiptConfiguration.smsConfiguration">
            <div *ngIf="(receiptConfiguration.enabledFor === 1 || receiptConfiguration.enabledFor === 2) && (receiptConfiguration.merchantDestination === 1 || receiptConfiguration.merchantDestination === 2)">
              <h3 class="mb-1 mt-3">Merchant SMS Settings</h3>
              <div class="bg-light border border-1 rounded p-3 pb-0">
                <mat-form-field class="w-100">
                  <mat-label>Merchant Phone Number</mat-label>
                  <input matInput [(ngModel)]="receiptConfiguration.smsConfiguration.merchantPhoneNumber"/>
                </mat-form-field>

                <mat-form-field *ngIf="false" class="w-100">
                  <mat-label>Merchant SMS Template</mat-label>
                  <input matInput [(ngModel)]="receiptConfiguration!.smsConfiguration!.merchantSmsTemplateId"/>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="(receiptConfiguration.enabledFor === 0 || receiptConfiguration.enabledFor === 2) && (receiptConfiguration.customerDestination === 1 || receiptConfiguration.customerDestination === 2)">
              <h3 class="mb-1 mt-3">Customer SMS Settings</h3>
              <div class="bg-light border border-1 rounded p-3 pb-0">
              <p>No configuration available.</p>
              <mat-form-field class="w-100" *ngIf="false">
                <mat-label>Customer SMS Template</mat-label>
                <input matInput [(ngModel)]="receiptConfiguration!.smsConfiguration!.customerSmsTemplateId"/>
              </mat-form-field>
              </div>
            </div>
          </div>

          <button (click)="deleteConfiguration()" *ngIf="!configLoading" mat-flat-button class="mt-4 d-block mon-bg-danger mon-border-danger border mon-text-danger flex-shrink-0">
            Delete Configuration
          </button>

          <div class="p-2 bg-light mt-4" *ngIf="false">
            <pre>{{ receiptConfiguration | json }}</pre>
          </div>
        </div>

      </div>
    </mat-card-content>
  </mat-card>
</div>
