import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OdinSettingsRoutingModule } from './odin-settings/odin-settings-routing.module';
import { OdinSettingsPageComponent } from './odin-settings/odin-settings-page/odin-settings-page.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  ReceiptConfigurationPageComponent
} from "./odin-settings/receipt-configuration-page/receipt-configuration-page.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FontAwesomeModule,
    MatProgressSpinner,
  ],
  declarations: [
    OdinSettingsPageComponent,
    ReceiptConfigurationPageComponent
  ],
  exports: [
    OdinSettingsPageComponent,
    ReceiptConfigurationPageComponent,
    OdinSettingsRoutingModule
  ],
})
export class OdinSettingsModule {}
